.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	color: var(--primary);
	background-color: var(--dark);
	padding: 20px 40px;
	border-bottom: 1px solid var(--primary);
}
